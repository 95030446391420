

















































































































































































































































































































































































































































































































































import * as R from 'ramda';
import { clone, dissoc, isNil } from 'ramda';
import { computed, defineComponent, inject, ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import { Card, ConfirmModal, FormBlock, TwButton, TwProgressBar, WizardTabs } from '@/app/components';
import { AppAPI, JobsAPI, UploadAPI } from '@/modules/data-checkin/api';
import { useStep } from '@/modules/data-checkin/composable/steps';
import { useFilters } from '@/app/composable';
import { StatusCode } from '@/modules/data-checkin/constants';
import ApiConfiguration from './ApiConfiguration.vue';
import MqttConfiguration from './MqttConfiguration.vue';
import FilesConfiguration, { FilesData } from './FilesConfiguration.vue';
import KafkaConfiguration from './KafkaConfiguration.vue';
import ExternalKafkaConfiguration from './ExternalKafkaConfiguration.vue';
import InternalApiConfiguration from './InternalApiConfiguration.vue';
import StepCompletionModal from '../../components/StepCompletionModal.vue';
import WizardActions from '../../components/WizardActions.vue';
import { renamings } from '@/app/utilities';
import store from '@/app/store';
import { UserRoles } from '@/app/constants';

export default defineComponent({
    name: 'Harvester',
    components: {
        ApiConfiguration,
        MqttConfiguration,
        InternalApiConfiguration,
        Card,
        FilesConfiguration,
        KafkaConfiguration,
        ExternalKafkaConfiguration,
        ConfirmModal,
        FormBlock,
        OrbitSpinner,
        TwButton,
        TwProgressBar,
        WizardTabs,
        WizardActions,
        StepCompletionModal,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    setup(props, { root }) {
        const isFeatureEnabled = inject('isEnabled');
        const contentRef = ref<any>(null);
        const tabs = ref([{ title: renamings('Setup Harvest Service') }, { title: 'Test and Review Configuration' }]);
        const harvesterValidationRef = ref<any>(null);
        const jobId = parseInt(`${props.id}`, 10);
        const { loading, exec, error } = useAxios(true);
        const activeTab = ref(0);
        const showConfirmModal = ref(false);
        const showUpdateFileDataModal = ref<boolean>(false);
        const uploading = ref(false);
        const source = ref<string | null>(null);
        const job = ref<any>(null);
        const step = ref<any>(null);
        const files = ref<FilesData>({
            sample: null,
            data: null,
        });
        const { formatBytes } = useFilters();
        const progress = ref<number>(0);
        const progressId = ref<number>(-1);
        const initializingKafka = ref(false);
        const initializingMqtt = ref(false);
        const saveInProgress = ref(false);
        const defaultBasePath = 'res';
        const { isFinalized, getNextStep } = useStep(step, job);
        const initialJobStepHash = ref<string | null>(null);
        const showFinalizeModal = ref(false);
        const nextStep = ref<any>(null);
        const cleaningConfig = ref<any>(null);
        const hasAdditionalFile = ref<boolean>(false);
        const steps = ref<any>(null);
        const isOnpremise = ref<boolean>(false);
        const finalizing = ref<boolean>(false);
        const parsedSampleData = ref<any>(null);

        const hasChanges = computed(() => {
            const hash = JSON.stringify(step.value);
            if (hash !== null && hash !== initialJobStepHash.value) {
                return true;
            }
            return !!files.value.data;
        });

        const isAdmin = computed(() => !!store.getters.auth.hasRole(UserRoles.Admin)).value;

        const mappingStepExists = computed(
            () => !!(steps.value && steps.value.find((jobStep: any) => jobStep.dataCheckinStepType.name === 'mapping')),
        );

        // Initialize
        exec(JobsAPI.get(jobId))
            .then((res) => {
                job.value = res?.data;
                isOnpremise.value = job.value.runnerId !== null;

                exec(JobsAPI.getJobSteps(jobId)).then(async (stepRes) => {
                    steps.value = stepRes?.data;
                    step.value = steps.value.find((jobStep: any) => jobStep.dataCheckinStepType.name === 'harvester');
                    if (!step.value.configuration) {
                        step.value.configuration = { source: null };
                        step.value.serviceVersion = process.env.VUE_APP_HARVESTER_VERSION;
                    } else if (step.value.configuration.source === 'file') {
                        await exec(JobsAPI.getStep(jobId, 'harvester')).then((response) => {
                            step.value.configuration = response?.data.configuration;
                        });
                    }
                    initialJobStepHash.value = JSON.stringify(step.value);
                    const cleaning = steps.value.find(
                        (jobStep: any) => jobStep.dataCheckinStepType.name === 'cleaning',
                    );
                    if (cleaning) cleaningConfig.value = cleaning.configuration;
                    if (isOnpremise.value) {
                        step.value.configuration.source = 'file';
                    }
                });
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    (root as any).$toastr.e('The job requested does not exist', 'Not Found');
                } else if (e.response.status === 403) {
                    (root as any).$toastr.e('You do not have permission to view this specific job', 'Not Authorized');
                } else {
                    (root as any).$toastr.e(e.response.data.message);
                }
                root.$router.push({ name: 'data-checkin-jobs' });
            });

        const isJobCompleted = computed(() => {
            let allStepsCompleted = true;
            if (steps.value) {
                steps.value.forEach((jobStep: any) => {
                    if (jobStep.status !== StatusCode.Completed) allStepsCompleted = false;
                });
            }
            return allStepsCompleted;
        });

        /*
        The user can upload more data (files) in a file harvester only if:
        a) all job steps have completed at least once
        b) there is no anonymisation step
        c) the cleaning step does not include rules other than 'DROP' and 'DEFAULT_VALUE'
        d) is not on premise
        */
        const canUploadMore = computed(() => {
            if (job.value && !isOnpremise.value) {
                const hasAnonymisation = steps.value.some((jobStep: any) => jobStep.order === 3);
                const executedOnce = !!steps.value.find((jobStep: any) => jobStep.order === 100).stats;
                const cleaningIncompatible =
                    !!cleaningConfig.value &&
                    cleaningConfig.value.fields
                        .flatMap((field: any) => field.constraints)
                        .some(
                            (constraint: any) =>
                                constraint.outliersRule.type !== 'DROP' &&
                                constraint.outliersRule.type !== 'DEFAULT_VALUE',
                        );

                return executedOnce && !hasAnonymisation && !cleaningIncompatible;
            }
            return false;
        });

        // Methods
        const getSampleFile = (stepData: any) => {
            if (stepData.configuration.source === 'internalApi' && stepData.configuration.dataType === 'textBinary') {
                const json = R.clone(job.value.sample);
                return new Blob([JSON.stringify(json, null, 2)], {
                    type: 'application/json',
                });
            }
            return files.value.sample;
        };

        const saveChanges = async (clearSample = false, showToaster = true) => {
            saveInProgress.value = true;
            const stepData = clone(step.value);
            if (stepData.status === StatusCode.Deprecated) {
                if (showToaster) {
                    (root as any).$toastr.e('The current job is deprecated and cannot be updated', 'Failed');
                }
                saveInProgress.value = false;
                return;
            }
            const response = await exec(UploadAPI.getPolicy(job.value.id, 'upload'));
            const policy = response?.data;
            let uploadResponseSample = null; // only in the case of 'externalKafka'
            if (stepData.configuration.source === 'api') {
                try {
                    stepData.configuration.auth.payload = JSON.parse(stepData.configuration.auth.payload);
                } catch (e) {
                    // do nothing
                }
                if (clearSample) {
                    stepData.configuration.response.data = null;
                }

                stepData.configuration.isSaved = true;
            } else if (stepData.configuration.source === 'file') {
                if (clearSample) {
                    stepData.configuration.response.data = null;
                }
            } else if (stepData.configuration.source === 'externalKafka') {
                uploadResponseSample = JSON.stringify(stepData.configuration.processedSample);
                stepData.configuration.processedSample = [];
                if (clearSample) {
                    stepData.configuration.response.data = null;
                    stepData.configuration.params.password = null;
                }
                stepData.configuration.isSaved = true;
            }
            await exec(JobsAPI.updateStep(stepData.id, stepData));
            if (stepData.status === StatusCode.Configuration) {
                let sampleConfig = null;
                if (files.value.sample) {
                    const sampleFile = getSampleFile(stepData);
                    sampleConfig = {
                        filename: `sample.${stepData.configuration.fileType}`,
                        size: sampleFile?.size,
                        mimeType: sampleFile?.type,
                    };

                    await exec(UploadAPI.file(sampleFile, sampleConfig.filename, policy));
                } else if (stepData.configuration.source === 'externalKafka') {
                    sampleConfig = {
                        filename: `sample.${stepData.configuration.fileType}`,
                        size: encodeURI(JSON.stringify(uploadResponseSample)).split(/%..|./).length - 1,
                        mimeType: 'application/json',
                    };
                    await exec(UploadAPI.file(uploadResponseSample, sampleConfig.filename, policy));
                }
                await exec(JobsAPI.update(job.value.id, { ...job.value, sampleConfig }));
            }
            // TODO: Upload any data files, update configuration.files array and clear files object
            if (files.value.data && files.value.data.length > 0) {
                uploading.value = true;
                for (let i = 0; i < files.value.data.length; i += 1) {
                    progress.value = 0;
                    progressId.value = i;
                    const file = files.value.data[i];
                    // eslint-disable-next-line no-await-in-loop
                    await exec(
                        UploadAPI.file(file, `${file?.name}`, policy, (progressEvent) => {
                            progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        }),
                    );
                    stepData.configuration.files.push({
                        filename: file.name,
                        size: file.size,
                        mimeType: file.type || 'application/octet-stream',
                    });
                }
                files.value.data = null;
                uploading.value = false;
                // Update step again, with the uploaded files
                const stepValue = dissoc('status', stepData);
                await exec(JobsAPI.updateStep(stepData.id, stepValue));
                if (stepData.configuration.source === 'file') {
                    await exec(JobsAPI.getStep(jobId, 'harvester')).then((res) => {
                        step.value.configuration = res?.data.configuration;
                    });
                }
            }
            saveInProgress.value = false;
            initialJobStepHash.value = JSON.stringify(step.value);

            if (hasAdditionalFile.value) {
                (root as any).$toastr.s('File data updated successfuly', 'Success');
                root.$router.push({ name: 'data-checkin-jobs' });
            } else if (showToaster) {
                (root as any).$toastr.s('Collector configuration saved successfuly', 'Success');
            }
        };

        const confirmSaveChanges = () => {
            if (hasAdditionalFile.value) {
                showUpdateFileDataModal.value = true;
            } else {
                saveChanges(false, true);
            }
        };

        const saveStep = async () => {
            await exec(JobsAPI.updateStep(step.value.id, step.value));
        };

        const initializeKafka = async () => {
            if (step.value.configuration.source === 'kafka') initializingKafka.value = true;
            await exec(JobsAPI.updateStep(step.value.id, step.value));
            if (!error.value) {
                await exec(JobsAPI.getStep(jobId, 'harvester')).then((res) => {
                    step.value.configuration = res?.data.configuration;
                });
            }
            if (step.value.configuration.source === 'kafka') initializingKafka.value = false;
        };

        const extractMqttDetails = (response: any) => {
            if (response.status <= 399 && response.status >= 200) {
                const { data } = response;
                console.log(`uri=${data.uri}`);
                try {
                    const uri: string = new String(data.uri).toString();
                    step.value.configuration.params.uri = uri;
                    step.value.configuration.params.username = uri
                        .replace('mqtts://', '')
                        .replace('mqtt://', '')
                        .split('@')[0]
                        .split(':')[0];
                    step.value.configuration.params.topic = step.value.configuration.params.username;
                    step.value.configuration.params.password = uri
                        .replace('mqtts://', '')
                        .replace('mqtt://', '')
                        .split('@')[0]
                        .split(':')[1];
                    step.value.configuration.params.port = uri
                        .replace('mqtts://', '')
                        .replace('mqtt://', '')
                        .split('@')[1]
                        .split(':')[1];
                    step.value.configuration.params.ssl = uri.includes('mqtts') ? true : false;
                    step.value.configuration.params.broker = uri
                        .replace('mqtts://', '')
                        .replace('mqtt://', '')
                        .split('@')[1]
                        .split(':')[0];
                } catch (currentError) {
                    console.error(currentError);
                }
            } else {
                console.log(response);
            }
        };

        const generateNewMqttURI = async () => {
            const response: any = await exec(AppAPI.getMQTTAccount());
            extractMqttDetails(response);
        };

        const initializeMqtt = async () => {
            if (step.value.configuration.source === 'mqtt') initializingMqtt.value = true;
            await generateNewMqttURI();
            await exec(JobsAPI.updateStep(step.value.id, step.value));
            if (!error.value) {
                await exec(JobsAPI.getStep(jobId, 'harvester')).then((res) => {
                    step.value.configuration = res?.data.configuration;
                });
            }
            if (step.value.configuration.source === 'mqtt') initializingMqtt.value = false;
        };

        const confirmSource = (value: string) => {
            source.value = value;
            showConfirmModal.value = true;
        };
        const setSource = async () => {
            // Initialize configuration object for each type
            switch (source.value) {
                case 'api':
                    step.value.configuration = {
                        source: 'api',
                        fileType: 'json',
                        isSaved: false,
                        params: {
                            method: 'GET',
                            url: '',
                            urlParams: [],
                            headers: [],
                            payload: null,
                            parameters: [],
                            headerTags: [],
                            ignoreCertificates: false,
                        },
                        pagination: {
                            type: 'no',
                            includeValues: 'query',
                            parameters: [],
                        },
                        auth: {
                            method: 'no',
                            url: '',
                            payload: null,
                            tokenType: 'Bearer',
                            accessToken: null,
                            loginTested: false,
                            headers: [],
                            ignoreCertificates: false,
                        },
                        retrieval: {
                            type: 'periodic',
                            endDate: null,
                        },
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            data: null,
                            selectedItems: [],
                            additional: [],
                        },
                    };
                    break;
                case 'mqtt':
                    step.value.configuration = {
                        source: 'mqtt',
                        fileType: 'json',
                        params: {
                            version: 3,
                            transport: 'tcp',
                            username: '',
                            password: '',
                            broker: '',
                            clean_session: true,
                            qos: 1,
                            retain: false,
                            uri: '',
                            base_topic: '',
                            topic: '',
                            ssl: false,
                        },
                        retrieval: {
                            endDate: null,
                        },
                        files: [],
                        isSampleCropped: false,
                    };
                    // step.value.configuration = {
                    //     source: 'mqtt',
                    //     fileType: 'json',
                    //     isSaved: false,
                    //     type: "external",
                    //     params: {
                    //         version: 3,
                    //         transport: 'tcp',
                    //         username: '',
                    //         password: '',
                    //         broker: '',
                    //         clean_session: true,
                    //         qos: 1,
                    //         retain: false,
                    //         uri: '',
                    //         base_topic: '',
                    //         topic: '',
                    //         ssl: false,
                    //     },
                    //     retrieval: {
                    //         endDate: null,
                    //     },
                    //     response: {
                    //         basePath: defaultBasePath,
                    //         multiple: true,
                    //         data: null,
                    //         selectedItems: [],
                    //         additional: [],
                    //     },
                    // };
                    break;
                case 'file':
                    step.value.configuration = {
                        source: 'file',
                        fileType: null,
                        params: {},
                        files: [],
                        isSampleCropped: false,
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            selectedItems: [],
                        },
                    };
                    break;
                case 'kafka':
                    step.value.configuration = {
                        source: 'kafka',
                        fileType: 'json',
                        params: {
                            topic: '',
                            url: '',
                            saslMechanism: null,
                            username: null,
                            password: null,
                        },
                        retrieval: {
                            endDate: null,
                        },
                        files: [],
                        isSampleCropped: false,
                    };
                    break;
                // case 'mqtt':
                //     step.value.configuration = {
                //         source: 'mqtt',
                //         fileType: 'json',
                //         params: {
                //             topic: '',
                //             url: '',
                //             saslMechanism: null,
                //             username: null,
                //             password: null,
                //         },
                //         retrieval: {
                //             endDate: null,
                //         },
                //         files: [],
                //         isSampleCropped: false,
                //     };
                //     break;
                case 'internalApi':
                    step.value.configuration = {
                        source: 'internalApi',
                        params: {
                            uploadQueryId: null,
                        },
                        dataType: 'text',
                        files: [],
                        fileType: 'json',
                        isSampleCropped: false,
                    };
                    break;
                case 'externalKafka':
                    step.value.configuration = {
                        source: 'externalKafka',
                        isSaved: false,
                        isSampleUploaded: false,
                        isSampleCropped: false,
                        fileType: 'json',
                        params: {
                            topic: '',
                            url: '',
                            saslMechanism: null,
                            username: null,
                            password: null,
                            groupId: null,
                        },
                        retrieval: {
                            endDate: null,
                        },
                        files: [],
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            data: null,
                            selectedItems: [],
                        },
                        processedSample: null,
                    };
                    break;
                default:
                    step.value.configuration = {
                        source: source.value,
                    };
            }
            showConfirmModal.value = false;
            if (step.value.configuration.source === 'kafka') {
                await initializeKafka();
            } else if (step.value.configuration.source === 'mqtt') {
                await initializeMqtt();
            } else {
                await exec(JobsAPI.updateStep(step.value.id, step.value));
            }
        };

        const setParsedSample = (parsedSample: any) => {
            parsedSampleData.value = parsedSample;
        };

        const setUploadSample = (parsedSample: any) => {
            if (step.value.configuration.source === 'externalKafka') {
                step.value.configuration.isSampleUploaded = !!parsedSample;
            }
            job.value.sample = parsedSample;
        };

        const setFiles = (changedFiles: any) => {
            if (changedFiles.sample !== undefined) {
                files.value.sample = changedFiles.sample;
                if (changedFiles.sample === null) {
                    job.value.sample = null;
                }
            }

            if (changedFiles.data !== undefined) {
                files.value.data = changedFiles.data ? Array.from(changedFiles.data) : null;
                if (canUploadMore.value) hasAdditionalFile.value = !!changedFiles.data;
            }
        };

        const removeFile = (file: any) => {
            if (files.value.data) {
                const idx = files.value.data.findIndex((f: any) => f === file);
                if (~idx) {
                    files.value.data.splice(idx, 1);
                }
            }
        };

        const finalize = async () => {
            finalizing.value = true;
            if (harvesterValidationRef.value && step.value.status !== StatusCode.Deprecated) {
                const valid = await harvesterValidationRef.value.validate();
                if (valid) {
                    await saveChanges(true, false);
                    await exec(JobsAPI.finalize(step.value.id)).then(() => {
                        getNextStep().then((stepTypeResponse: any) => {
                            nextStep.value = stepTypeResponse;
                            showFinalizeModal.value = true;
                        });
                    });
                }
            }
            finalizing.value = false;
        };

        const changeKafkaInitStatus = (isKafkaLoading: boolean) => {
            initializingKafka.value = isKafkaLoading;
        };

        const changeMqttInitStatus = (isMqttLoading: boolean) => {
            initializingMqtt.value = isMqttLoading;
        };

        const scrollUp = () => {
            contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
        };

        const jobConfigChanged = (config: any) => {
            job.value.config = config;
            if (!isNil(config?.basePath)) {
                step.value.configuration.response.basePath = config.basePath;
            }
            if (!isNil(config?.multiple)) {
                step.value.configuration.response.multiple = config.multiple;
            }
        };

        const setCroppedSample = (cropped: boolean) => {
            step.value.configuration.isSampleCropped = cropped;
        };

        const nextTab = () => {
            if (job.value.sample && step.value.configuration.source === 'internalApi') {
                for (let i = 0; i < job.value.sample.length; i += 1) {
                    if (step.value.configuration.dataType === 'textBinary') {
                        job.value.sample[i][
                            `${'_uploaded_file'}`
                        ] = `${process.env.VUE_APP_BACKEND_URL}/api/query/file/UPLOADED-FILE-ID`;
                    } else if (Object.keys(job.value.sample[i]).includes('_uploaded_file')) {
                        delete job.value.sample[i][`${'_uploaded_file'}`];
                    }
                }
            }
            activeTab.value += 1;
            scrollUp();
        };

        const previousTab = () => {
            activeTab.value -= 1;
            scrollUp();
        };
        const setProcessedSample = (kafkaProcessedSample: any) => {
            step.value.configuration.processedSample = kafkaProcessedSample;
            if (!step.value.configuration.isSampleUploaded) {
                job.value.sample = kafkaProcessedSample;
            }
        };

        const updateConnectionDetails = (connectionDetails: any) => {
            step.value.configuration.params = connectionDetails;
        };

        const resetConnectionDetails = () => {
            step.value.configuration.params.username = null;
            step.value.configuration.params.password = null;
        };

        const enableFinalize = computed(() => {
            if (step.value) {
                const selectedItemsExist =
                    (step.value.configuration.source === 'api' ||
                        step.value.configuration.source === 'externalKafka') &&
                    step.value.configuration.response.selectedItems.length > 0;
                const notAPIorExternalKafka =
                    step.value.configuration.source !== 'api' && step.value.configuration.source !== 'externalKafka';

                if (
                    activeTab.value === tabs.value.length - 1 &&
                    (selectedItemsExist || notAPIorExternalKafka) &&
                    step.value.status !== StatusCode.Deprecated
                ) {
                    return true;
                }
            }

            return false;
        });

        const changeLoadingState = (state: boolean) => {
            loading.value = state;
        };

        return {
            renamings,
            isFeatureEnabled,
            contentRef,
            activeTab,
            confirmSource,
            error,
            files,
            finalize,
            formatBytes,
            harvesterValidationRef,
            job,
            loading,
            progress,
            progressId,
            removeFile,
            confirmSaveChanges,
            saveChanges,
            setFiles,
            setSource,
            setUploadSample,
            showConfirmModal,
            showUpdateFileDataModal,
            StatusCode,
            step,
            tabs,
            uploading,
            initializingKafka,
            initializingMqtt,
            changeKafkaInitStatus,
            changeMqttInitStatus,
            isFinalized,
            saveStep,
            initializeKafka,
            initializeMqtt,
            jobId,
            scrollUp,
            saveInProgress,
            defaultBasePath,
            jobConfigChanged,
            nextTab,
            previousTab,
            hasChanges,
            showFinalizeModal,
            nextStep,
            updateConnectionDetails,
            setProcessedSample,
            hasAdditionalFile,
            isJobCompleted,
            canUploadMore,
            mappingStepExists,
            enableFinalize,
            resetConnectionDetails,
            setCroppedSample,
            isOnpremise,
            finalizing,
            changeLoadingState,
            parsedSampleData,
            setParsedSample,
            isAdmin,
        };
    },
});
